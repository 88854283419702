import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const en = {
    ...englishMessages,
    // terms refers to entity and field
    resources: { 
        users: {
            role: 'Role',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedAt: 'Updated at',
            updatedBy: 'Updated by',
            email: 'Email',
            fieldGroups: {
                identity: 'Identity',
                password: 'Password'
            },
            errors: {
                passwordMismatch: 'Password mismatch'
            }
        },
        banners: {
            fieldGroups: {
                banner: 'Banner'
            }
        },
        greetingCards: {
            fieldGroups: {
                greetingCard: 'Greeting Card'
            }
        },
        roles: {
            description: 'Description',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
        categories: {
            fieldGroups: {
                category: 'Category'
            },
            description: 'Description',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
        packages: {
            fieldGroups: {
                package: 'Package'
            },
            description: 'Description',
            price: 'Price',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
        items: {
            fieldGroups: {
                item: 'Item'
            },
            description: 'Description',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
        campaigns: {
            fieldGroups: {
                campaign: 'Campaign'
            },
            description: 'Description',
            qty: 'Quantity',
            targetPrice: 'Target',
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
        orders: {
            fieldGroups: {
                order: 'Order'
            },
            createdAt: 'Created at',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
        },
    },
    // refers to page translation
    page: {
        menu: {
            sales: {
                name: 'Sales',
                orders: 'Orders'
            },
            catalog: {
                name: 'Packages',
                categories: 'Categories',
                packages: 'Packages',
                items: 'Items',
                campaigns: 'Campaigns',
                sales: 'Sales'
            },
            customers: 'Customers',
            users: {
                name: 'Users',
                users: 'Users',
                roles: 'Roles'
            },
            campaigns: {
                name: 'Campaigns',
                campaigns: 'Campaigns'
            },
            others: {
                name: 'Others',
                banners: 'Banners',
                greetingCards: 'Greeting Cards'
            }
        }
    },
}

export default polyglotI18nProvider(() => en, 'en');