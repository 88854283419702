import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';


import packages from '../pages/packages';
import orders from '../pages/orders';
import users from '../pages/users';
import banners from '../pages/banners';
import SubMenu from './SubMenu';
import categories from '../pages/category';
import items from '../pages/items';
import campaigns from '../pages/campaigns';

type MenuName = 'menuUsers' | 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuCampaigns' | 'menuOthers';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuUsers: false,
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuCampaigns: false,
        menuOthers: false
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                name="page.menu.users.name"
                icon={<users.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.users.users`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/roles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.users.roles`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="page.menu.sales.name"
                icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/orders"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.sales.orders`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                />
                {/* <MenuItemLink
                    to="/invoices"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.invoices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<invoices.icon />}
                    dense={dense}
                /> */}
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCampaigns')}
                isOpen={state.menuCampaigns}
                name="page.menu.campaigns.name"
                icon={<campaigns.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/campaigns"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.catalog.campaigns`, {
                        smart_count: 2,
                    })}
                    leftIcon={<campaigns.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="page.menu.catalog.name"
                icon={<packages.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/packages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.catalog.packages`, {
                        smart_count: 2,
                    })}
                    leftIcon={<packages.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/categories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.catalog.categories`, {
                        smart_count: 2,
                    })}
                    leftIcon={<categories.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/items"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.catalog.items`, {
                        smart_count: 2,
                    })}
                    leftIcon={<items.icon />}
                    dense={dense}
                />
            </SubMenu>
            {/* <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                name="page.menu.customers"
                icon={<visitors.icon />}
                dense={dense}
            > */}
                {/* <MenuItemLink
                    to="/customers"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visitors.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/segments"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    dense={dense}
                /> */}
            {/* </SubMenu> */}
            {/* <MenuItemLink
                to="/reviews"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                dense={dense}
            /> */}
            <SubMenu
                handleToggle={() => handleToggle('menuOthers')}
                isOpen={state.menuOthers}
                name="page.menu.others.name"
                icon={<banners.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/banners"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.others.banners`, {
                        smart_count: 2,
                    })}
                    leftIcon={<banners.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/greetingCards"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`page.menu.others.greetingCards`, {
                        smart_count: 2,
                    })}
                    leftIcon={<banners.icon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
