import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import { SxProps, Typography } from '@mui/material';
import { User } from 'common/types/record';

const UserLinkField = (_: FieldProps<User>) => {
    const record = useRecordContext<User>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/users/${record.id}`}>
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
                // sx={props.sx}
            >
                {record.username}
            </Typography>
        </Link>
    );
};

UserLinkField.defaultProps = {
    source: 'username',
};

export default UserLinkField;
