import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material'

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <Typography
            color={theme.palette.secondary.light}
            variant='h5'
        >
            KadoKita
        </Typography>
    );
};

export default Logo;
