// in src/comments.js
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
    DateField,
    EditButton,
    useTranslate,
    NumberField,
    RecordContextProvider,
    useListContext,
    TextField,
} from 'react-admin';

// import AvatarField from './AvatarField';
// import SegmentsField from './SegmentsField';
import { Package } from '../../common/types/record';

const MobileGrid = () => {
    const translate = useTranslate();
    const { data, isLoading } = useListContext<Package>();

    if (isLoading || data.length === 0) {
        return null;
    }

    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: '0.5rem 0' }}>
                        <CardHeader
                            title={`${record.name}`}
                            subheader={
                                <>
                                    {translate(
                                        'resources.packages.updatedAt'
                                    )}
                                    &nbsp;
                                    <DateField source="updatedAt" />
                                </>
                            }
                            action={<EditButton />}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography variant="body2">
                                {translate(
                                    'resources.packages.description'
                                )}
                                :&nbsp;
                                <TextField source="description" />
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.packages.price'
                                )}
                                :&nbsp;
                                <NumberField source="price" />
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export default MobileGrid;
