import OrderIcon from '@mui/icons-material/AttachMoney';

import OrderList from './OrderList';
// import OrderEdit from './OrderEdit';

const Order = {
    list: OrderList,
    // edit: OrderEdit,
    icon: OrderIcon,
};

export default Order;
