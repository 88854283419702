import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    email,
    ImageInput,
    ImageField,
    ReferenceInput,
    SelectInput,
    NumberInput,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { ToolbarWithoutDelete } from 'layouts/ToolbarWithoutDelete';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    if (!values.description) {
        errors.description = 'ra.validation.required';
    }

    return errors;
};

const ItemEdit = () => (
    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
            toolbar={<ToolbarWithoutDelete />}
        >
            <SectionTitle label="resources.items.fieldGroups.item" />
            <Box>
                <TextInput source="name" isRequired fullWidth />
                <TextInput source="description" isRequired fullWidth />
            </Box>
        </SimpleForm>
    </Edit>
);

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default ItemEdit;
