import React from "react";
import { Admin, Resource, localStorageStore } from "react-admin";
import i18nProvider from './utils/i18Provider';
import authProvider from './utils/authProvider';
import dataProvider from "./utils/dataProvider";
import { darkTheme, lightTheme } from './layouts/themes';
import { Layout, Login } from './layouts';
import { Dashboard } from "./pages/dashboard";
import packages from './pages/packages'
import users from "pages/users";
import roles from "pages/roles";
import banners from "pages/banners";
import greetingCards from "pages/greetingCards";
import categories from "pages/category";
import items from "pages/items";
import campaigns from "pages/campaigns"
import orders from "pages/orders"


const App = () => {
    const APIUrl = process.env.REACT_APP_API_HOST ?? "localhost:5000" 
    return (
        <Admin
            title=""
            dataProvider={dataProvider(APIUrl)}
            store={localStorageStore(undefined, 'Kadokita')}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
        >
            {/* <CustomRoutes>
                <Route path="/segments" element={<Segments />} />
            </CustomRoutes> */}
            <Resource name="packages" {...packages} />
            <Resource name="users" {...users} />
            <Resource name="roles" {...roles} />
            <Resource name="banners" {...banners} />
            <Resource name="greetingCards" {...greetingCards} />
            <Resource name="categories" {...categories} />
            <Resource name="items" {...items} />
            <Resource name="campaigns" {...campaigns} />
            <Resource name="orders" {...orders} />
        </Admin>
    )
};

export default App;
