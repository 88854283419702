import CategoryIcon from '@mui/icons-material/Category';

import CategoryList from './CategoryList';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';

const Category = {
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit,
    icon: CategoryIcon,
};

export default Category;
