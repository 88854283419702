const util = {
  formatErrorDisplay: (errObj: any, alt?: string) : string => {
    console.log('error:', errObj);

    if (errObj) {
      if (errObj.message)
        return errObj?.message;
      else if (errObj.response) {
        if(errObj.response.message) {
          return errObj.response.message;
        }
        else if (errObj.response.data) {
          return JSON.stringify(errObj.response.data);
        }
        return JSON.stringify(errObj.response);
      }

      return JSON.stringify(errObj);
    }
    
    return alt ?? 'Error. No response';
  },
}

export default util;