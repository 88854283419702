import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    email,
    ImageInput,
    ImageField,
    ReferenceInput,
    SelectInput,
    NumberInput,
    FileInput,
    useDataProvider,
    useNotify,
    useRedirect,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    if (!values.description) {
        errors.description = 'ra.validation.required';
    }

    return errors;
};

const PackageCreate = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const postSave = (data: any) => {
        dataProvider.createWithUpload('packages', { data })
        .then(({ data }: any) => {
            notify('ra.notification.created', { type: 'info' })
            redirect('list', 'packages');
        })
        .catch((err: any) => {
            notify(err.response?.data?.message ?? err.message, { type: 'error' })
        });
    }
    return (
        <Create>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                validate={validateForm}
                onSubmit={postSave}
            >
                <SectionTitle label="resources.packages.fieldGroups.package" />
                <Box>
                    <TextInput source="name" isRequired fullWidth />
                    <TextInput source="description" isRequired fullWidth />
                    <ReferenceInput source="categoryId" reference="categories">
                        <SelectInput optionText="name" isRequired fullWidth />
                    </ReferenceInput>
                    <ReferenceArrayInput source="itemIds" reference="items">
                        <SelectArrayInput optionText="name" isRequired fullWidth />
                    </ReferenceArrayInput>
                    <NumberInput source='price' isRequired fullWidth />
                    <ImageInput source="file">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </Box>
            </SimpleForm>
        </Create>
    );
}

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default PackageCreate;
