import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    useTranslate,
    email,
    ImageInput,
    ImageField,
    Edit,
    useRecordContext,
} from 'react-admin';
import { Box, Typography, Card, CardMedia } from '@mui/material';
import BannerImg from './BannerImg';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    if (!values.description) {
        errors.description = 'ra.validation.required';
    }

    return errors;
};

const BannerCreate = () => {
    return (
        <Edit title={`Edit Banner`}>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                validate={validateForm}
            >
                <SectionTitle label="resources.banners.fieldGroups.banner" />
                <Box>
                    <BannerImg />
                    <TextInput source="name" isRequired fullWidth />
                    <TextInput source="description" isRequired fullWidth />
                </Box>
            </SimpleForm>
        </Edit>
    );
}

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default BannerCreate;
