import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import { SxProps, Typography } from '@mui/material';
import { Role } from 'common/types/record';

const RoleLinkField = (_: FieldProps<Role>) => {
    const record = useRecordContext<Role>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/roles/${record.id}`}>
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
                // sx={props.sx}
            >
                {record.name}
            </Typography>
        </Link>
    );
};

RoleLinkField.defaultProps = {
    source: 'name',
};

export default RoleLinkField;
