import UserIcon from '@mui/icons-material/AccountBox';

import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

const User = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    icon: UserIcon,
};

export default User;
