import BannerIcon from '@mui/icons-material/Event';

import BannerList from './BannerList';
import BannerCreate from './BannerCreate';
import BannerEdit from './BannerEdit';

const Banner = {
    list: BannerList,
    create: BannerCreate,
    edit: BannerEdit,
    icon: BannerIcon,
};

export default Banner;
