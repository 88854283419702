import * as React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    TextField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

// import SegmentsField from './SegmentsField';
// import SegmentInput from './SegmentInput';
import UserLinkField from './UserLinkField';
import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';

const visitorFilters = [
    <SearchInput source="username" alwaysOn />,
    // <NullableBooleanInput source="has_ordered" />,
    // <NullableBooleanInput source="has_newsletter" defaultValue />,
    // <SegmentInput source="groups" />,
];

const UserList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={isSmall ? visitorFilters : undefined}
            sort={{ field: 'username', order: 'ASC' }}
            perPage={25}
            aside={<UserListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <UserLinkField source='username' />
                    <TextField source="email" />
                    <TextField 
                        source="userRole.role.name" 
                        label="resources.users.role" 
                    />
                    <DateField source="createdAt" />
                    <TextField 
                        source="createdByUser.username" 
                        label="resources.users.createdBy" 
                    />
                    <DateField source="updatedAt" />
                    <TextField 
                        source="updatedByUser.username" 
                        label="resources.users.updatedBy" 
                    />
                    { /* <NumberField
                        source="nb_commands"
                        label="resources.customers.fields.commands"
                        sx={{ color: 'purple' }}
                    />
                    <NumberField
                        source="total_spent"
                        options={{ style: 'currency', currency: 'IDR' }}
                    />
                    <DateField source="latest_purchase" showTime />
                    <BooleanField source="has_newsletter" label="News." /> */}
                    {/* <SegmentsField source="groups" /> */}
                </Datagrid>
            )}
        </List>
    );
};

export default UserList;
