import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    email,
    ImageInput,
    ImageField,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    if (!values.description) {
        errors.description = 'ra.validation.required';
    }

    return errors;
};

const CategoryCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
            <SectionTitle label="resources.categories.fieldGroups.category" />
            <Box>
                <TextInput source="name" isRequired fullWidth />
                <TextInput source="description" isRequired fullWidth />
            </Box>
        </SimpleForm>
    </Create>
);

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default CategoryCreate;
