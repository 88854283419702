import CampaignIcon from '@mui/icons-material/Collections';
import CampaignList from './CampaignList';
import CampaignEdit from './CampaignEdit';
import CampaignCreate from './CampaignCreate';

const Campaign = {
    list: CampaignList,
    create: CampaignCreate,
    edit: CampaignEdit,
    icon: CampaignIcon,
};

export default Campaign
