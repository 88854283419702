import GreetingCardIcon from '@mui/icons-material/Event';

import GreetingCardList from './GreetingCardList';
import GreetingCardCreate from './GreetingCardCreate';
import GreetingCardEdit from './GreetingCardEdit.tsx';

const GreetingCard = {
    list: GreetingCardList,
    create: GreetingCardCreate,
    edit: GreetingCardEdit,
    icon: GreetingCardIcon,
};

export default GreetingCard;
