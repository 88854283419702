import * as React from 'react';
import { Card, CardMedia } from '@mui/material';
import { useRecordContext } from 'react-admin';

const BannerImg = () => {
    const record = useRecordContext<any>();
    if (!record) return null;
    return (
        <Card sx={{ display: 'inline-block' }}>
            <CardMedia
                component="img"
                src={`${process.env.REACT_APP_API_HOST}/${record.url}`}
                alt={record.name}
                sx={{ maxWidth: '42em', maxHeight: '15em' }}
            />
        </Card>
    );
};

export default BannerImg;
