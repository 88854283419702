import { setAuthorizationHeader } from 'common/axios';
import key from 'common/constants/key';
import { AuthProvider, UserIdentity } from 'react-admin';
import ls from './localStorage';
import apiAuth from 'api/apiAuth';
import { LoginResult } from 'common/types/auth';

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
      return apiAuth.login({ username, password })
        .then((res: any) => {
          console.log(res)
          if (res?.accessToken) {
            ls.set(key.lsUser, res);
          } else {
            throw new Error(res?.message)
          }
        })
        .catch((err: any) => { 
          throw new Error(err.response?.data?.message ?? err.message)
        })
    },
    logout: () => {
      ls.remove(key.lsUser);
      return Promise.resolve();
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401) {
          localStorage.removeItem(key.lsUser);
          return Promise.reject({ message: false });
          //return Promise.reject({ message: 'Unauthorized user!' });
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve()
    },
    checkAuth: () =>
      ls.load(key.lsUser) ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
      try {
        const userInfo = ls.load<LoginResult>(key.lsUser)?.userInfo as UserIdentity;
        return Promise.resolve({ ...userInfo, fullName: userInfo.username });
      } catch(err) {
        return Promise.reject(err)
      }
    }
};

export default authProvider;
