import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import ls from './localStorage';
import key from 'common/constants/key';
import { LoginResult } from 'common/types/auth';
import { DataProvider, withLifecycleCallbacks } from 'react-admin';

const dataProvider = (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
) => ({
    getList: (resource: any, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sort: JSON.stringify([field, order]),
            page: JSON.stringify(page),
            perPage: JSON.stringify(perPage),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }),
        };

        return httpClient(url, options).then(({ headers, json }: any) => {
            return {
                data: json.data,
                total: json.total
            };
        });
    },

    getOne: (resource: any, params: any) => {
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }),
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(({ json }: any) => {
            return {
                data: json,
            }
        })
    },

    getMany: (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, options).then(({ json }: any) => ({ data: json.data, total: json.total }));
    },

    getManyReference: (resource: any, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }),
        };

        return httpClient(url, options).then(({ headers, res }: any) => {
            return {
                data: res.data,
                total: res.total,
            };
        });
    },

    update: (resource: any, params: any) => {
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        });
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            headers
        }).then(({ status }: any) => ({ status }))
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource: any, params: any) =>
        Promise.all(
            params.ids.map((id: any) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }: any) => json.id) })),

    create: (resource: any, params: any) => {
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        });

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers
        }).then(({ json }: any) => ({ data: { id: '' } }))
    },

    createWithUpload: (resource: any, params: any) => {
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        });
      
        const formData = new FormData();
        console.log(params.data);
        formData.append('file', params.data.file.rawFile);
    
        // Append other dynamic properties to formData
        Object.keys(params.data).forEach(key => {
        if (key !== 'file') {
            formData.append(key, params.data[key]);
        }
        });
    
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: formData,
            headers: headers
        })
        .then(({ json }: any) => ({ data: { id: '' } }))
    },

    delete: (resource: any, params: any) => {
        const token = ls.load<LoginResult>(key.lsUser)?.accessToken;
        const headers = new Headers({
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${token}`
        });

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers
        }).then(({ json }: any) => ({ data: { id: '' } }))
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource: any, params: any) =>
        Promise.all(
            params.ids.map((id: any) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }: any) => json.id),
        })),
});
    
// eslint-disable-next-line import/no-anonymous-default-export
export default (url: string) => withLifecycleCallbacks(dataProvider(url) as unknown as DataProvider, [
    {
        resource: 'users',
        beforeUpdate: async (params: any) => {
            console.log(params)
            return {
                ...params,
                data: {
                    ...params.data,
                    role: params.data.userRole.roleId
                }
            }
        }
    }
]);