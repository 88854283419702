import PackageIcon from '@mui/icons-material/Collections';
import PackageList from './PackageList';
import PackageEdit from './PackageEdit';
import PackageCreate from './PackageCreate';

const Package = {
    list: PackageList,
    create: PackageCreate,
    edit: PackageEdit,
    icon: PackageIcon,
};

export default Package
