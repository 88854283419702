import RoleIcon from '@mui/icons-material/AccountBox';

import RoleList from './RoleList';
// import RoleCreate from './RoleCreate';
// import RoleEdit from './RoleEdit';

const Role = {
    list: RoleList,
    // create: RoleCreate,
    // edit: RoleEdit,
    icon: RoleIcon,
};

export default Role;
