import { LoginResult } from 'common/types/auth';
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

import key from "common/constants/key";
import ls from "utils/localStorage";
import util from 'utils/util';

const lsUser = ls.load<LoginResult>(key.lsUser);

const instanceLogin = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  headers: {
    Authorization: `Bearer ${lsUser?.accessToken}`,
  },
});

instance.interceptors.response.use(
  <T>(response: AxiosResponse<T>) => response.data, 
  errorHandler
);

instanceLogin.interceptors.response.use(
  <T>(response: AxiosResponse<T>) => response.data, 
  errorHandler
);

const setAuthorizationHeader = (token: string | null | undefined) => {
  if (token) 
    instance.defaults.headers.common.authorization = `Bearer ${token}`;
  else 
    delete instance.defaults.headers.common.authorization;
};

export { instance, instanceLogin, setAuthorizationHeader };

function errorHandler(error: any) {
  if (error) {
    if (error.response) {
      if (error.response.status === 401){
        setTimeout(function () {
          ls.remove(key.lsUser);
          ls.set("logout", Date.now().toString());
    
          document.location.href = "/";
        }, 3000);

        toast.error("Session expired. Please login again");
      }
      else{
        toast.error(util.formatErrorDisplay(error));
      }

      return Promise.reject(error);
    }
  }
}