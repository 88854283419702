import * as React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    TextField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

// import SegmentsField from './SegmentsField';
// import SegmentInput from './SegmentInput';
import PackageLinkField from './PackageLinkField';
import MobileGrid from './MobileGrid';
import PackageListAside from './PackageListAside';

const visitorFilters = [
    <SearchInput source="name" alwaysOn />,
];

const PackageList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={isSmall ? visitorFilters : undefined}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={25}
            aside={<PackageListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <PackageLinkField source='name' />
                    <TextField source="description" />
                    <NumberField source="price" />
                    <DateField source="createdAt" />
                    <TextField 
                        source="createdByUser.username" 
                        label="resources.users.createdBy" 
                    />
                    <DateField source="updatedAt" />
                    <TextField 
                        source="updatedByUser.username" 
                        label="resources.users.updatedBy" 
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default PackageList;
