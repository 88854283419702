import * as React from 'react';
import {
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    Edit,
    ReferenceInput,
    SelectInput,
    useDataProvider,
} from 'react-admin';
import { Box, Typography } from '@mui/material';

export const validateForm = async (
    values: Record<string, any>,
    dataProvider: any
): Promise<Record<string, any>> => {
    const errors = {} as any;
    if (!values.username) {
        errors.first_name = 'ra.validation.required';
    }
    if (!values.email) {
        errors.email = 'ra.validation.required';
    } else {
        const error = email()(values.email);
        if (error) {
            errors.email = error;
        }
    }
    if (values.password && values.password !== values.confirm_password) {
        errors.confirm_password =
            'resources.users.errors.passwordMismatch';
    }
    if (values.userRole.roleId) {
        const role = await dataProvider.getOne('roles', {
            id: values.userRole.roleId,
        });
        if (role) {
            values.userRole.role = role.data;
        }
    }
    return errors;
};

const UserCreate = () => {
    const dataProvider = useDataProvider();
    return (
        <Edit>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                validate={async (v) => await validateForm(v, dataProvider)}
            >
                <SectionTitle label="resources.users.fieldGroups.identity" />
                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source="username" isRequired fullWidth />
                    </Box>
                </Box>
                <TextInput type="email" source="email" isRequired fullWidth />
                <Separator />
                <Box>
                    <ReferenceInput label="Role" source="userRole.roleId" reference="roles">
                        <SelectInput optionText="name" isRequired fullWidth />
                    </ReferenceInput>
                </Box>
                <Separator />
                <SectionTitle label="resources.users.fieldGroups.password" />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput source="password" fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <PasswordInput source="confirm_password" fullWidth />
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    );
}

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserCreate;
